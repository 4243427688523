<template>
  <div>
    <canvas ref="pieChart"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  PieController,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(
  PieController,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  ChartDataLabels,
)

export default {
  name: 'PieChart',
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  mounted() {
    this.createChart()
  },
  methods: {
    createChart() {
      new Chart(this.$refs.pieChart, {
        type: 'pie',
        data: this.chartData,
        options: this.options,
      })
    },
  },
}
</script>

<style scoped></style>
