<template>
  <BlockUI :blocked="showLoading">
    <Card class="contracheque card-mobile">
      <template #title>
        <div class="col-12">
          <Breadcrumb :home="home" :model="breadcrumbitems" /></div
      ></template>
      <template #content>
        <div class="contanier">
          <div class="col d-flex flex-row-reverse">
            <Button
              icon="pi pi-arrow-left"
              class="p-button-rounded p-button-plain p-button-outlined"
              @click="retornar()" />
          </div>

          <div class="row">
            <div class="col d-flex justify-content-end">
              <Button @click="realizarDownload()"> Imprimir </Button>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex flex-column">
              <table id="header">
                <thead>
                  <tr>
                    <th colspan="6" style="text-align: center">
                      <img
                        v-if="contracheque.orgao == 'CODATA'"
                        src="@/img/logo-codata.png"
                        class="mt-2 mb-2"
                        style="
                          max-width: 150px;
                          max-height: 150px;
                          display: inline-block;
                        " />

                      <img
                        v-if="imagemSrc == null"
                        src="@/img/logo-pb.png"
                        style="
                          max-width: 150px;
                          max-height: 150px;
                          display: inline-block;
                        " />
                      <img
                        v-if="contracheque.orgao != 'CODATA'"
                        :src="imagemSrc"
                        style="max-width: 50px; max-height: 50px" />
                    </th>
                  </tr>

                  <tr id="title">
                    <th colspan="6">
                      <label>{{ contracheque.orgao }}</label
                      ><br />
                      <span>{{ contracheque.cnpj }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="6" class="text-center align-middle">
                      <label>Contracheque</label>
                    </th>
                  </tr>
                  <tr>
                    <th style="width: 40%" colspan="3">
                      <label>Lotação</label><br />
                      <span>{{ contracheque.lotacao }}</span>
                    </th>
                    <th style="width: 20%" colspan="3">
                      <label>Competência</label><br />
                      <span>{{ contracheque.periodo }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th style="width: 40%" colspan="6">
                      <label>Unidade de Trabalho</label><br />
                      <span>{{ contracheque.unidadeDeTrabalho }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th style="width: 50%" colspan="6">
                      <label>Servidor</label><br />
                      <span>{{ contracheque.servidor }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th style="width: 20%" colspan="3">
                      <label>Matrícula</label><br />
                      <span>{{ contracheque.matricula }}</span>
                    </th>
                    <th style="width: 30%" colspan="3">
                      <label>CPF</label><br />
                      <span>{{ contracheque.cpf | mascaraCpf }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th style="width: 50%" colspan="6">
                      <label>Cargo</label><br />
                      <span>{{ contracheque.cargo }}</span>
                    </th>
                  </tr>
                  <tr>
                    <!--<th style="width: 30%" colspan="3">
                      <label>Unidade Pagadora</label><br />
                      <span>{{ contracheque.unidadePagadora }}</span>
                    </th>-->
                    <th style="width: 50%" colspan="3">
                      <label>Regime</label><br />
                      <span>{{ contracheque.regime }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="6">
                      <label>Classe Funcional</label><br />
                      <span>{{
                        formatarClasseFuncional(contracheque.classeFuncional)
                      }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="3">
                      <label>T. S. Estado</label><br />
                      <span>{{ formataTempo(contracheque.tempoEstado) }}</span>
                    </th>

                    <th colspan="3">
                      <label>T. S. Quinquênio</label><br />
                      <span>{{ contracheque.tempoQuinquenio }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="3">
                      <label>T. S. Aposentado</label><br />
                      <span>{{ contracheque.tempoAposentadoria }}</span>
                    </th>

                    <!--<th colspan="3">
                      <label>T. S. Adquirido</label><br />
                      <span>{{
                        formataTempo(contracheque.tempoAdquirido)
                      }}</span>
                    </th>-->
                  </tr>

                  <tr>
                    <th
                      style="
                        width: 40%;
                        border-left: 1px solid black;
                        padding-left: 10px;
                        font-weight: bold;
                      "
                      class="text-center"
                      colspan="6">
                      <label>Código Certificador</label><br />
                      <span>{{ contracheque.codigoCertificador }}</span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <table id="header">
                <thead>
                  <tr>
                    <th>TIPO</th>
                    <th>DISCRIMINAÇÃO</th>
                    <th style="text-align: right">PRAZO</th>
                    <th style="text-align: right">VALOR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="lancamento in contracheque.lancamentos"
                    :key="lancamento.id">
                    <td>{{ lancamento.tipo }}</td>
                    <td>{{ lancamento.discriminacao }}</td>
                    <td style="text-align: right">{{ lancamento.prazo }}</td>
                    <td style="text-align: right">
                      {{ formatarValoresDeLancamentos(lancamento.valor) }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>BRUTO</th>
                    <th class="text-right">DESCONTOS</th>
                    <th colspan="2" class="text-right">LIQUIDO</th>
                  </tr>
                  <tr>
                    <td>{{ formatarValorBruto(contracheque.bruto) }}</td>

                    <td class="text-right">
                      {{ formatarValorBruto(contracheque.descontos) }}
                    </td>
                    <td colspan="2" class="text-right">
                      {{
                        formatarValorLiquido(
                          contracheque.bruto,
                          contracheque.descontos,
                        )
                      }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="d-flex align-items-center flex-column">
            <div class="p-2">
              <h4><b>Valide aqui seu contracheque</b></h4>
            </div>
            <div class="p-2">
              <qrcode-vue
                :value="qrCodeUrl"
                :size="qrCodeSize"
                level="H"></qrcode-vue>
            </div>
            <div class="p-2 text-center">
              <h4><b>Aponte a câmera do seu celular e acesse o link.</b></h4>
            </div>
            <div class="p-2 text-center">
              <h5>
                <b
                  >Ou acesse a validação <br />
                  atráves da url:</b
                >
              </h5>
              <a :href="qrCodeUrl"
                ><b>{{ qrCodeUrl }}</b></a
              >
            </div>
          </div>
        </div>
      </template>
    </Card>
    <Card class="contracheque card-desktop">
      <template #title>
        <div class="row">
          <div class="col-auto d-flex align-items-center">
            <Button
              icon="pi pi-arrow-left"
              class="p-button-rounded p-button-plain p-button-outlined"
              @click="retornar()" />
          </div>
          <div class="col">
            <Breadcrumb :home="home" :model="breadcrumbitems" />
          </div>
        </div>
        <div class="row flex-row-reverse pr-5" style="font: 10px normal">
          <span>Consulta realizada em: {{ dataConsulta }}</span>
        </div>
        <div
          class="row d-flex align-items-end"
          style="border-bottom: 1px solid #efefef">
          <div class="col pl-8">
            <img
              v-if="contracheque.orgao == 'CODATA'"
              src="@/img/logo-codata.png"
              class="mt-2 mb-2"
              style="
                max-width: 200px;
                max-height: 200px;
                display: inline-block;
              " />

            <img
              v-if="imagemSrc == null"
              src="@/img/logo-pb.png"
              style="
                max-width: 200px;
                max-height: 200px;
                display: inline-block;
              " />
            <img
              v-if="contracheque.orgao != 'CODATA'"
              :src="imagemSrc"
              style="max-width: 50px; max-height: 50px" />
          </div>
          <div class="col d-flex flex-row-reverse h-50">
            <Button
              style="background-color: #071d41"
              @click="realizarDownload()">
              Imprimir
            </Button>
          </div>
        </div>
      </template>
      <template #content>
        <div class="contanier">
          <div class="row">
            <div class="col d-flex flex-column">
              <table id="header">
                <thead>
                  <tr>
                    <th style="width: 40%" colspan="2">
                      <label>Lotação</label><br />
                      <span>{{ verificaValor(contracheque.lotacao) }}</span>
                    </th>
                    <th style="width: 30%" colspan="2">
                      <label>Unidade de Trabalho</label><br />
                      <span>{{
                        verificaValor(contracheque.unidadeDeTrabalho)
                      }}</span>
                    </th>
                    <th style="width: 15%" colspan="1">
                      <label>Código Certificador</label><br />
                      <span>{{
                        verificaValor(contracheque.codigoCertificador)
                      }}</span>
                    </th>
                    <th style="width: 15%" colspan="1">
                      <label>Mês/Ano</label><br />
                      <span>{{ verificaValor(contracheque.periodo) }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th style="width: 40%" colspan="2">
                      <label>Nome</label><br />
                      <span>{{ verificaValor(contracheque.servidor) }}</span>
                    </th>
                    <th style="width: 15%" colspan="1">
                      <label>Matrícula</label><br />
                      <span>{{ verificaValor(contracheque.matricula) }}</span>
                    </th>
                    <th style="width: 15%" colspan="1">
                      <label>CPF</label><br />
                      <span>{{
                        verificaValor(contracheque.cpf)
                          | verificaValor(mascaraCpf)
                      }}</span>
                    </th>
                    <th style="width: 30%" colspan="2">
                      <label>Cargo</label><br />
                      <span>{{ verificaValor(contracheque.cargo) }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th style="width: 15%">
                      <label>Classe Funcional</label><br />
                      <span>{{
                        formatarClasseFuncional(
                          verificaValor(contracheque.classeFuncional),
                        )
                      }}</span>
                    </th>

                    <th style="width: 15%">
                      <label>Regime</label><br />
                      <span>{{ verificaValor(contracheque.regime) }}</span>
                    </th>

                    <th style="width: 15%">
                      <label>T. S. Estado</label><br />
                      <span>{{
                        verificaValor(formataTempo(contracheque.tempoEstado))
                      }}</span>
                    </th>

                    <th style="width: 15%">
                      <label>T. S. Aposentado</label><br />
                      <span>{{
                        verificaValor(contracheque.tempoAposentadoria)
                      }}</span>
                    </th>

                    <th style="width: 10%">
                      <label>CLF</label><br />
                      <span>{{ verificaValor(contracheque.clf) }}</span>
                    </th>

                    <th style="width: 10%">
                      <label>Símbolo</label><br />
                      <span>{{ verificaValor(contracheque.simbolo) }}</span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <table id="body" class="table table-bordered">
                <thead id="lancamentosHeader">
                  <tr>
                    <th style="width: 6%">Código</th>
                    <th>Discriminação</th>
                    <th style="width: 8%">Prazo</th>
                    <th>Vantagens</th>
                    <th>Descontos</th>
                  </tr>
                </thead>
                <tbody id="lancamentosBody">
                  <tr
                    v-for="lancamento in contracheque.lancamentos"
                    :key="lancamento.id">
                    <td>
                      {{
                        separarDiscriminacao(lancamento.discriminacao).codigo
                      }}
                    </td>
                    <td class="text-left">
                      {{ separarDiscriminacao(lancamento.discriminacao).texto }}
                    </td>
                    <td v-if="lancamento.prazo">
                      {{ formataPrazo(lancamento.prazo) }}
                    </td>
                    <td v-else>-</td>
                    <td v-if="lancamento.tipo == 'VANTAGEM'" class="text-right">
                      {{ formatarValoresDeLancamentos(lancamento.valor) }}
                    </td>
                    <td v-else class="text-right">-</td>
                    <td v-if="lancamento.tipo == 'DESCONTO'" class="text-right">
                      {{ formatarValoresDeLancamentos(lancamento.valor) }}
                    </td>
                    <td v-else class="text-right">-</td>
                  </tr>
                  <tr id="sumario" class="font-semibold">
                    <td colspan="2"></td>
                    <td>Totais</td>
                    <td class="text-right">
                      {{ formatarValorBruto(contracheque.bruto) }}
                    </td>
                    <td class="text-right">
                      {{ formatarValorBruto(contracheque.descontos) }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" class="font-bold valor-liquido">
                      Valor Líquido:
                      {{
                        formatarValorLiquido(
                          contracheque.bruto,
                          contracheque.descontos,
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            class="row d-flex justify-content-center py-4"
            style="border-bottom: 1px solid #efefef">
            <div
              id="mensagemDengue"
              class="text-center"
              style="font-size: 16px">
              <p>
                Combater a Dengue é Papel de Todos! Elimine possíveis criadouros
                e promova a conscientização. <br />
                Em caso de sintomas ligue: <b>Alô Saúde 0800 281 6591</b>.
              </p>
            </div>
          </div>
        </div>

        <div class="row py-4 d-flex justify-content-around flex-row-reverse">
          <div>
            <PieChart
              v-if="liquido && descontos"
              :chartData="pieChartData"
              :options="pieChartOptions"
              style="width: 250px" />
          </div>
          <div class="d-flex align-items-center">
            <qrcode-vue
              :value="qrCodeUrl"
              :size="qrCodeSize"
              level="H"></qrcode-vue>

            <div class="py-3 ml-3" style="max-width: 40%; font-size: 12px">
              Aponte a câmera do seu celular para validar seu contracheque
              <br />
              Ou acesse a validação atráves da url: <br />
              <a :href="qrCodeUrl"
                ><b>{{ qrCodeUrl }}</b></a
              >
            </div>
          </div>
        </div>
      </template>
    </Card>
  </BlockUI>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import PieChart from '../../../components/shared/charts/PieChart.vue'
import ContrachequeService from '@/service/ContrachequeService'

export default {
  components: {
    QrcodeVue,
    PieChart,
  },

  props: {
    id: {
      type: String,
      require: true,
      default: '',
    },
    matriculaId: {
      type: String,
      require: true,
      default: '',
    },
  },

  data() {
    return {
      contracheque: {},
      liquido: 0,
      descontos: 0,
      showLoading: true,
      imagemSrc: null,
      qrCodeSize: 130,
      qrCodeUrl: '',
      home: { icon: 'pi pi-home', to: '/' },
      breadcrumbitems: [
        {
          label: 'Contracheques',
          to: { name: 'ContraChequeLista' },
        },
      ],
      activeIndex: 0,
      tabIndex: {
        'consignacao-detalhe': 0,
        'consignacao-parcelas': 1,
      },
    }
  },

  computed: {
    dataConsulta() {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date().toLocaleDateString('pt-BR', options)
    },
    pieChartData() {
      return {
        labels: ['Líquido', 'Descontos'],
        datasets: [
          {
            data: [this.liquido, this.descontos],
            backgroundColor: ['#071d41', '#efefef'],
            hoverBackgroundColor: ['#071d41', '#efefef'],
          },
        ],
      }
    },
    pieChartOptions() {
      return {
        responsive: true,
        plugins: {
          datalabels: {
            formatter: (value) => {
              let sum = 0
              sum += Number(this.liquido) + Number(this.descontos)
              let percentage = ((value * 100) / sum).toFixed(2) + '%'
              return percentage
            },
            color: function (context) {
              let index = context.dataIndex
              let backgroundColor = context.dataset.backgroundColor[index]
              return backgroundColor === '#071d41' ? '#FFFFFF' : '#000000'
            },
            font: {
              size: 16,
              weight: 'bold',
            },
          },
        },
      }
    },
  },

  created() {
    this.contrachequeService = new ContrachequeService(this.$http)
    this.carregarDados()
  },
  mounted() {},

  methods: {
    carregarDados() {
      this.contrachequeService
        .obterContracheque(this.id, this.matriculaId)
        .then((res) => {
          this.contracheque = res
          this.liquido = this.contracheque.bruto - this.contracheque.descontos
          this.descontos = this.contracheque.descontos
          this.carregarLogo()
          this.gerarQrcode()
          this.showLoading = false
        })
        .catch((err) => {
          this.showToastError(err)
        })
    },

    carregarLogo() {
      const imagem = new Image()
      if (this.contracheque.logo != null) {
        imagem.src = 'data:image/png;base64,' + this.contracheque.logo

        imagem.onload = () => {
          this.imagemSrc = imagem.src
        }
      }
    },

    formataTempo(valor) {
      if (valor == null || valor == undefined || valor == '' || valor == '0') {
        return ''
      } else {
        return valor
      }
    },

    formatarClasseFuncional(valor) {
      if (valor == '' || valor == null || valor == undefined) {
        return ''
      } else {
        return valor
      }
    },

    gerarQrcode() {
      if (window.location) {
        this.qrCodeUrl = `http://${window.location.host}/validacaoContracheque`

        this.qrCodeUrl += `?codigo=${this.removerMascara(
          this.contracheque.codigoCertificador,
        )}`
        this.qrCodeUrl += `&matricula=${this.removerMascara(
          this.contracheque.matriculaId,
        )}`
        this.qrCodeUrl += `&bruto=${this.contracheque.bruto}`
      }
    },

    removerMascara(data) {
      return data.replace(/\D/g, '')
    },

    formataPrazo(valor) {
      if (valor == '' || valor == null || valor == undefined) {
        return ''
      } else {
        return parseInt(valor)
      }
    },
    formatarValorBruto(valor) {
      valor = valor / 100
      const doubleValue = Number(valor)
      return this.formatarValoresDeLancamentos(doubleValue.toFixed(2))
    },

    formatarValorLiquido(bruto, descontos) {
      bruto = bruto / 100
      descontos = descontos / 100
      return this.formatarValoresDeLancamentos(
        Number(bruto - descontos).toFixed(2),
      )
    },

    formatarValoresDeLancamentos(valor) {
      const value = parseFloat(valor.replace(',', '.'))

      if (!isNaN(value)) {
        const formattedValue = value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        if (formattedValue.includes('R$')) {
          return formattedValue
        }
        return 'R$ ' + formattedValue
      }
      if (valor.includes('R$')) {
        return valor
      }
      return 'R$ ' + valor
    },

    realizarDownload() {
      window.print()
    },

    retornar() {
      this.$router.back()
    },

    showToastError(err) {
      const mensagem = err.response
        ? err.response.data.message
        : 'Erro de conexão com a aplicação.'
      this.$toast.add({
        severity: 'error',
        summary: mensagem,
        life: 10000,
      })
    },

    separarDiscriminacao(disc) {
      const regex = /^(\d+)\s(.*)/
      const match = disc.match(regex)

      const codigo = match[1]
      const texto = match[2]
      return { codigo, texto }
    },

    verificaValor(valor) {
      return valor ? valor : '-'
    },
  },
}
</script>
<style scoped>
#header span {
  font-weight: normal;
}

.contracheque table {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
  /* padding: 10px; */
}

.valor-liquido {
  background-color: #dee2e9 !important;
  font-weight: bold;
}

/* .contracheque table thead {
  border: 1px solid black;
} */

/* .contracheque table tfoot {
  border: 1px solid black;
} */

.contracheque table#header thead tr#title th {
  padding-left: 0%;
  text-align: center;
  font-size: 15px;
}
.contracheque table#header thead tr th {
  padding-left: 10px;
  border: 1px solid #d7d7d7;
}

.contracheque table#body {
  margin-top: 10px;
  text-align: center;
}

/* .contracheque table#body thead tr th {
  padding-left: 10px;
}

.contracheque table#body tbody tr td {
  padding-left: 10px;
} */

/* .contracheque table#body tfoot {
  text-align: center;
} */

#lancamentosHeader {
  background-color: #071d41;
  color: white;
}

#lancamentosBody {
  background-color: #efefef;
}

@media print {
  ::v-deep .p-button,
  .p-button.p-button-icon-only.p-button-rounded,
  .p-breadcrumb {
    display: none;
  }

  @page {
    margin-left: 0.5in;
    margin-right: 0.5in;
    margin-top: 0;
    margin-bottom: 0;
  }

  .contracheque {
    background-color: white;
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0 5px 0 5px;
    font-size: 14px;
    line-height: 18px;
  }

  #lancamentosHeader tr,
  #lancamentosHeader th {
    background-color: inherit !important;
    color: inherit !important;
  }

  #lancamentosBody tr,
  #lancamentosBody td {
    background-color: inherit !important;
  }
}
</style>
