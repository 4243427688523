export default class ContrachequeService {
  constructor(http) {
    this._http = http
  }

  async getListaContrachequeDoServidor(cpf, matriculaId) {
    const { data } = await this._http.get(`/api/contracheque/listar`, {
      params: { cpf: cpf, matriculaId: matriculaId },
    })
    return data
  }

  async obterContracheque(id, matriculaId) {
    const { data } = await this._http.get(`/api/contracheque/detalhe`, {
      params: { id: id, matriculaId: matriculaId },
    })

    return data
  }

  async getListaInformesRendimentoDoServidor(matriculaId) {
    const { data } = await this._http.get(
      `/api/contracheque/informes_de_rendimento`,
      {
        params: { matriculaId: matriculaId },
      },
    )

    return data
  }

  async downloadInformeRendimento(map) {
    let formData = new FormData()

    map = Object.fromEntries(map)

    formData.append('jsonInformeRendimentosDto', JSON.stringify(map))

    const { data } = await this._http.post(
      `/api/contracheque/informes_de_rendimento/download`,
      formData,
      {
        responseType: 'blob',
      },
    )

    return data
  }

  async validarContracheque(map) {
    const { data } = await this._http.get(
      `/api/contracheque/validarContracheque?codigo=${map.get(
        'codigo',
      )}&matricula=${map.get('matricula')}&bruto=${map.get('bruto')}`,
    )

    return data
  }
}
